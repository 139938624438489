'use client';

import * as React from 'react';
import Error from 'next/error';

type GlobalErrorProps = {
  error: unknown;
};
const GlobalError: React.FunctionComponent<GlobalErrorProps> = () => {
  return (
    <html>
      <body>
        <Error statusCode={500} />
      </body>
    </html>
  );
};
GlobalError.displayName = 'GlobalError';
export default GlobalError;
